<!-- 支付页面头部 -->
<template>
  <div class="headBox">
    <div class="headCon">
      <img :src="require('@/assets/icon/pageHead.png')" alt="" />
      <div class="operate">
        <div v-if="!token" class="userDetail notLogin">
          <img :src="require('@/assets/base-user.png')" alt="" />
          <span>
            <span>
              <span class="linkSpan" @click="$fun.routerToPage('/login')"
                ><!-- 登录 -->{{ $fanyi("登录") }}</span
              >
            </span>
            <span class="fg"> / </span>
            <span>
              <span class="linkSpan" @click="$fun.routerToPage('/register')"
                ><!--  -->{{ $fanyi("注册") }}</span
              >
            </span>
          </span>
        </div>
        <div v-else class="userDetail">
          <img
            class="userHeadImg"
            style="cursor: pointer"
            @click="$fun.routerToPage('/user/index')"
            svg
            :src="userInfo.head_img"
            v-if="userInfo.head_img"
          />
          <img
            class="userHeadImg"
            style="cursor: pointer"
            @click="$fun.routerToPage('/user/index')"
            v-else
            :src="require('@/assets/base-user.png')"
          />
          <div class="userLoginInfoBox">
            <p class="toUserIndex">Hola&nbsp;{{ userInfo.user_name }}</p>
            <p>
              <span
                class="routerBtn"
                @click="
                  $fun.routerToPage('/user/index');
                  getUser();
                "
              >
                {{ $fanyi("个人中心") }}
              </span>
              <span style="font-size: 12px"> / </span>
              <span class="routerBtn" @click="loginOut"
                >{{ $fanyi("退出登录") }}
              </span>
            </p>
          </div>

          <div class="dropDownBox" v-if="false">
            <span
              @click="
                $fun.routerToPage('/user/index');
                getUser();
              "
            >
              {{ $fanyi("个人中心") }}
            </span>
            <span @click="loginOut">{{ $fanyi("退出登录") }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logininfo: localStorage.getItem("logininfo"),
    };
  },
  components: {},
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    token() {
      if (this.$store.state.userInfo) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},

  methods: {
    openCart() {
      if (localStorage.getItem("user_token")) {
        this.$fun.routerToPage("/cart");
      } else {
        this.$store.commit("getactivePage", "/cart");
        this.$store.commit("getshowLoginDialog", true); //打开登录弹窗
      }
    },
    getUser() {
      this.$api.EuropegetUserInfo().then((res) => {
        this.$store.commit("userData", res.data);
      });
    },

    // 返回首页
    backHome() {
      // 假如当前在首页就重新刷新页面
      //console.log(this.$route.name);
      if (this.$route.name == "homepage") {
        return this.$router.go(0);
      }
      this.$fun.routerToPage("/");
      this.$store.commit("getsearchInfo", "");
    },

    // 退出登录
    loginOut() {
      localStorage.removeItem("backgroundOperationID");
      sessionStorage.removeItem("backendPersonnel");
      localStorage.removeItem("user_token");
      sessionStorage.removeItem("user_id");

      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        this.$fun.routerToPage("/");
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.headBox {
  position: sticky;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 112px;
  background-color: white;
  border: 1px solid #e3e3e3;
  .headCon {
    width: 1400px;
    padding: 0 9px 0 7px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .operate {
      .userDetail {
        width: 200px;

        $userImgSize: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        position: relative;

        &.notLogin {
          text-align: right;
          width: 234.56px;
          justify-content: flex-end;

          img {
            width: 26px;
            height: 26px;
            margin-right: 10px;
          }
        }

        &:hover {
          .dropDownBox {
            height: 64px;
          }
        }

        .dropDownBox {
          transition: 0.3s;
          position: absolute;
          top: 100%;
          left: 49px;
          width: 120px;
          height: 0;
          overflow: hidden;
          background: #ffffff;
          box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, 0.2);
          border-radius: 10px;
          z-index: 777;

          > span {
            display: block;
            cursor: pointer;
            text-align: center;
            line-height: 32px;
            width: 120px;
            height: 32px;
            font-size: 16px;

            font-weight: 400;

            &:hover {
              color: $homePageSubjectColor;
              background: #e2e5ff;
            }
          }
        }

        // 用户头像大小
        $userHeadImgSize: 22px;

        .userHeadImg {
          border-radius: 50%;
          width: $userHeadImgSize;
          height: $userHeadImgSize;
          margin-right: 6px;
        }

        .moRenTouXiang {
          width: $userHeadImgSize;
          height: $userHeadImgSize;
          margin-right: 5px;
        }

        span {
          font-size: 14px;

          font-weight: 500;
          color: #000000;

          > * {
            text-decoration: underline;
          }

          .fg {
            text-decoration: unset;
          }

          &:nth-child(3) a {
            color: $homePageSubjectColor;
          }
        }

        .userLoginInfoBox {
          .toUserIndex {
            cursor: pointer;
            font-size: 13px;
            text-decoration: unset !important;

            &:hover {
              color: $homePageSubjectColor;
            }
          }

          .routerBtn {
            cursor: pointer;
            font-size: 13px;

            &:hover {
              color: $homePageSubjectColor;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="paymentPage">
    <paymentHead v-if="step == 1" />
    <HomePageTop v-else />
    <!-- 订单支付页面 -->
    <div v-if="!isPeiSongDan" v-loading.fullscreen.lock="payLoading">
      <Dialog ref="dialog" :config="config" />
      <howItWorksVue ref="howItWorksDia"></howItWorksVue>
      <!-- <stepShowVue></stepShowVue> -->
      <div class="payStep1 payPage" v-if="step == 1">
        <div class="payLeftCon">
          <!-- 联系人 -->
          <div class="linkman">
            <h1>
              <span>
                {{ $fanyi("进口商联系人") }}
              </span>
              <el-select
                v-model="actNum.jinkoushang"
                @change="findValue($event, 'jinkoushang')"
              >
                <el-option
                  v-for="(additem, addindex) in userAddressList.jinkoushang"
                  :key="additem.id"
                  :label="$fanyi('地址') + (addindex + 1)"
                  :value="additem.id"
                >
                </el-option>
              </el-select>
            </h1>
            <jinkoushangForm ref="jinkoushangref"></jinkoushangForm>
          </div>
          <!-- 收货地址 -->
          <div class="linkman">
            <h1>
              <span>
                {{ $fanyi("支付页面收货地址") }}
              </span>
              <el-select
                v-model="actNum.shouhuodizhi"
                @change="findValue($event, 'shouhuodizhi')"
              >
                <el-option
                  v-for="(additem, addindex) in userAddressList.shouhuodizhi"
                  :key="additem.id"
                  :label="$fanyi('地址') + (addindex + 1)"
                  :value="additem.id"
                >
                </el-option>
              </el-select>
            </h1>
            <dizhiForm ref="dizhiref"></dizhiForm>
          </div>
          <div class="goodsBox" :class="{ GoodsArrAll: showGoodsArrAll }">
            <h1>
              {{ $fanyi("订单摘要") }}
              <span class="goodsBoxtishi"
                >{{ $fanyi("订单号") }}:{{ $route.query.order_sn }}</span
              >
            </h1>

            <div class="goodsArr">
              <div class="goodsHead goodsTr">
                <div class="goodsTd goodshe goodsName"></div>
                <div class="goodsTd goodshe goodsNumber">
                  {{ $fanyi("购买金额") }}
                </div>
                <div class="goodsTd goodshe goodsPrice">
                  {{ $fanyi("产品的支出总额") }}
                </div>
              </div>
              <div
                class="goodsOpt goodsTr"
                v-for="goodsItem in buyGoodsArr"
                :key="'good' + goodsItem.id"
              >
                <div class="goodsTd goodsBo goodsName">
                  <el-popover placement="right" trigger="hover">
                    <img
                      :src="goodsItem.pic"
                      alt=""
                      style="width: 300px; height: 300px"
                    />

                    <el-image slot="reference" :src="goodsItem.pic">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                  <el-popover placement="bottom" trigger="hover" width="800">
                    {{ goodsItem.goods_title }}
                    <span slot="reference" class="goodsTitle">
                      {{ goodsItem.goods_title }}
                    </span>
                  </el-popover>
                </div>
                <div class="goodsTd goodsBo goodsNumber">
                  {{ goodsItem.confirm_num }}
                </div>
                <div class="goodsTd goodsBo goodsPrice">
                  <p class="eu">
                    {{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(goodsItem.china_amount.sum_amount)
                      )
                    }}€
                  </p>
                  <p>
                    ({{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsItem.china_amount.sum_amount /
                            datas.exchange_rate
                        )
                      )
                    }}￥)
                  </p>
                </div>
                <div class="henXian"></div>
              </div>
            </div>
            <div class="showAllRow">
              <img
                class="jiahao"
                src="../../assets/icon/jiahao_fill.svg"
                alt=""
                v-if="!showGoodsArrAll"
                @click="showGoodsArrAll = !showGoodsArrAll"
              />
              <img
                class="jiahao"
                src="../../assets/icon/jianhao.svg"
                alt=""
                v-else
                @click="showGoodsArrAll = !showGoodsArrAll"
              />
            </div>
          </div>
        </div>
        <div class="payRightCon">
          <h1 class="fukuan">{{ $fanyi("付款方式") }}</h1>
          <div>
            <div class="payTypeChiose">
              <div class="allPayType">
                <div @click="payType = 1">{{ $fanyi("信用卡") }}</div>
                <div @click="payType = 0">{{ $fanyi("银行转帐") }}</div>
              </div>
              <div
                class="chioseBlock"
                :class="{ xinyongka: payType == 0 }"
              ></div>
            </div>
            <div class="detailedCost">
              <span>{{ $fanyi("产品总价") }}</span>
              <span
                >{{ $fun.EURNumSegmentation(datas.order_price_eu || 0) }}€</span
              >
            </div>
            <div class="detailedCost">
              <span>{{ $fanyi("运费总价") }}</span>
              <span
                >{{
                  $fun.EURNumSegmentation(
                    $fun.ceil(datas.logistics_price_eu) || 0
                  )
                }}€</span
              >
            </div>
            <div class="totalPrice">
              <span>{{ $fanyi("产品支出总额") }}</span>
              <span>
                <!-- {{ payAllCNPrice }} -->
                {{
                  $fun.EURNumSegmentation(
                    isPeiSongDan
                      ? $fun.ceil(payAllCNPrice)
                      : datas.sum_discount_price_eu
                  )
                }}€</span
              >
            </div>
            <div class="payInfoBox">
              <div class="bankPay" v-if="payType == 0">
                <div class="bankIcon">
                  <img src="../../assets/icon/santander.png" alt="" />
                </div>
                <h2 class="bankName">{{ $fanyi("接收银行信息") }}</h2>
                <div class="banktishi">
                  {{
                    $fanyi(
                      "使用以下银行信息通过您的网上银行或移动应用程序进行转账。一旦订单被处理，就会进行购买。"
                    )
                  }}
                </div>
                <div class="howToWork" @click="openHelpDia">
                  {{ $fanyi("如何运作") }}
                </div>
                <div class="canKao">
                  {{ $fanyi("使用订单号作为付款概念/参考:") }}
                </div>
                <div class="jiaoYiHao">
                  {{ $route.query.order_sn }}
                </div>
                <div class="shiLiXinXi">
                  <p>
                    <span>{{ $fanyi("收件人名称") }}</span
                    ><span>ELOSUNG EUROPE S.A.</span>
                  </p>
                  <p>
                    <span>{{ $fanyi("公司地址cz") }}</span
                    ><span
                      >Carrer de Francesc Teixidó, 43, 08918 Badalona,
                      Barcelona, España
                    </span>
                  </p>
                  <p>
                    <span>{{ $fanyi("银行账户") }}</span
                    ><span>ES95 0049 1819 1329 1107 9908 </span>
                  </p>
                  <p>
                    <span>{{ $fanyi("SWIFT帐户") }}</span
                    ><span>BSCHESMMXXX</span>
                  </p>
                  <p>
                    <span>{{ $fanyi("银行名称") }}</span
                    ><span>Santander</span>
                  </p>

                  <p><span>Nº CIF</span><span>A-05270319</span></p>
                </div>
                <div class="bankPayForm">
                  <!-- 银行汇款信息 -->
                  <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                  >
                    <el-form-item
                      :label="$fanyi('汇款日期') + '：'"
                      prop="pay_date"
                    >
                      <el-date-picker
                        v-model="ruleForm.pay_date"
                        type="date"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :placeholder="$fanyi('选择日期')"
                      >
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item
                      :label="$fanyi('汇款人') + '：'"
                      prop="pay_realname"
                    >
                      <el-input v-model="ruleForm.pay_realname"></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$fanyi('汇款金额') + '：'"
                      prop="pay_amount"
                    >
                      <el-input
                        v-model="ruleForm.pay_amount"
                        @change="set_pay_amount()"
                      ></el-input>
                      <!-- <span class="payPrice">
                        {{
                          $fun.EURNumSegmentation(
                            $fun.ceil(ruleForm.pay_amount)
                          )
                        }}
                      </span> -->
                    </el-form-item>
                    <el-form-item
                      :label="$fanyi('备注') + '：'"
                      prop="pay_remark"
                    >
                      <el-input v-model="ruleForm.pay_remark"></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$fanyi('汇款截图') + '：'"
                      prop="pay_image_url"
                    >
                      <div class="upLoadBox">
                        <el-upload
                          class="avatar-uploader"
                          action="https://laravel.rakumart.es/api/common/uploadFile"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          name="file"
                          :before-upload="beforeAvatarUpload"
                        >
                          <div v-if="ruleForm.pay_image_url" class="elseImg">
                            <el-image
                              :src="ruleForm.pay_image_url"
                              class="pay_image"
                            >
                              <div slot="error" class="image-slot">
                                <i class="el-icon-folder-checked"></i>
                              </div>
                              <div slot="placeholder">
                                <i class="el-icon-loading"></i>
                              </div>
                            </el-image>
                          </div>

                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                          ></i>
                        </el-upload>
                        <a
                          class="fileName"
                          target="_blank"
                          :href="fileName.url"
                        >
                          {{ fileName.name }}
                        </a>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
                <button class="bankPayBtn" @click="submitForm('ruleForm')">
                  {{ $fanyi("进行付款") }}
                </button>
              </div>
              <div class="creditCardPay" v-else>
                <div class="cardIcon">
                  <div>
                    <img src="../../assets/icon/santander.png" alt="" />
                  </div>
                  <div><img src="../../assets/icon/redses.png" alt="" /></div>
                </div>
                <div class="xuanze">
                  <span>{{ $fanyi("Redsys Santander支付网关") }}</span>

                  <div class="cardiconBox">
                    <div><img src="../../assets/icon/visa.png" alt="" /></div>
                    <div>
                      <img
                        src="../../assets/icon/mastercard_old_payment_method_icon_142751.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        src="../../assets/icon/maestro_old_payment_method_icon_142753.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="wangguantishi">
                  {{ $fanyi("您将被重定向到Redsys Santander支付网关") }}
                </div>
                <div style="display: none">
                  <form
                    action="https://sis.redsys.es/sis/realizarPago"
                    method="post"
                    id="redsys_form"
                    name="redsys_form"
                  >
                    <input
                      type="hidden"
                      name="Ds_MerchantParameters"
                      :value="cardPayData.Ds_MerchantParameters"
                    />
                    <input
                      type="hidden"
                      name="Ds_Signature"
                      :value="cardPayData.Ds_Signature"
                    />
                    <input
                      type="hidden"
                      name="Ds_SignatureVersion"
                      :value="cardPayData.Ds_SignatureVersion"
                    />
                    <input
                      type="submit"
                      name="btn_submit"
                      id="btn_id"
                      value="Enviar"
                      style="font-size: 14px; color: #ff00c1"
                    />
                  </form>
                </div>

                <button class="cardPayBtn" @click="goCreditCardPay">
                  {{ $fanyi("银行卡进行付款") }}
                </button>
                <div class="zhenCe">
                  <el-checkbox v-model="checked"></el-checkbox>
                  <span class="xieYiwenzi">
                    He leído y acepto los
                    <span
                      class="Link"
                      @click="
                        $fun.routerToPage(
                          '/condiciones-generales-de-contratacion'
                        )
                      "
                      style="margin-left: 0"
                      >términos y condiciones</span
                    >
                    así como la
                    <span
                      class="Link"
                      @click="$fun.routerToPage('/politica-privacidad')"
                      style="margin-left: 0"
                      >política de privacidad</span
                    >
                    y autorizo el envío de comunicaciones sobre los productos de
                    Rakumart.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="payStep2" v-if="step == 2">
        <div class="payStep2Left">
          <div class="successMesBox">
            <div class="successMesBoxCon">
              <div class="succTitle">
                <div class="icon">
                  <div class="green">
                    <img :src="require('@/assets/icon/gougou.svg')" alt="" />
                  </div>
                </div>
                <span>{{ $fanyi("付款成功") }}</span>
              </div>
              <div class="succBody">
                {{
                  $fanyi(
                    "谢谢，付款成功。很快你就会收到一封电子邮件，里面有确认和详细信息。"
                  )
                }}
              </div>
              <button
                @click="$fun.routerToPage('/user/index')"
                class="successBtn"
              >
                {{ $fanyi("控制面板") }}
              </button>
            </div>
          </div>
          <paySuccessInfoBlockVue :title="$fanyi('支付页面收货地址')">
            <div class="jinkoushang">
              <div class="jinkoushanginfo">
                <p>{{ goodsdatas.orderAddress.consignee_address.contacts }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.address }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.zip_code }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.city }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.country }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.mobile }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.email }}</p>
              </div>
              <changeInformationVue> </changeInformationVue>
            </div>
          </paySuccessInfoBlockVue>
          <paySuccessInfoBlockVue :title="$fanyi('进口商联系人')">
            <div class="jinkoushang">
              <div class="jinkoushanginfo">
                <p>{{ goodsdatas.orderAddress.importer_address.contacts }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.company }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.cnpj }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.mobile }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.email }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.address }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.zip_code }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.city }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.country }}</p>
              </div>
              <changeInformationVue xiuGaiLeiXin="jinkoushang">
              </changeInformationVue>
            </div>
          </paySuccessInfoBlockVue>

          <paySuccessInfoBlockVue :title="$fanyi('订单摘要')">
            <div
              class="goodsBox marginhave"
              :class="{ GoodsArrAll: showGoodsArrAll }"
            >
              <div class="goodsArr">
                <div class="goodsHead goodsTr">
                  <div class="goodsTd goodshe goodsName">
                    {{ $fanyi("产品") }}
                  </div>
                  <div class="goodsTd goodshe goodsNumber">
                    {{ $fanyi("数量") }}
                  </div>
                  <div class="goodsTd goodshe goodsPrice">
                    {{ $fanyi("产品支出总额") }}
                  </div>
                </div>
                <div
                  class="goodsOpt goodsTr"
                  v-for="goodsItem in buyGoodsArr"
                  :key="'good' + goodsItem.id"
                >
                  <div class="goodsTd goodsBo goodsName">
                    <el-popover placement="right" trigger="hover">
                      <img
                        :src="goodsItem.pic"
                        alt=""
                        style="width: 300px; height: 300px"
                      />

                      <el-image slot="reference" :src="goodsItem.pic">
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                        <div slot="placeholder">
                          <i class="el-icon-loading"></i>
                        </div>
                      </el-image>
                    </el-popover>
                    <el-popover placement="bottom" trigger="hover" width="800">
                      {{ goodsItem.goods_title }}
                      <span slot="reference" class="goodsTitle">
                        {{ goodsItem.goods_title }}
                      </span>
                    </el-popover>
                  </div>
                  <div class="goodsTd goodsBo goodsNumber">
                    {{ goodsItem.confirm_num }}
                  </div>
                  <div class="goodsTd goodsBo goodsPrice">
                    <p>
                      {{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(goodsItem.china_amount.sum_amount)
                        )
                      }}€
                    </p>
                    <p>
                      ({{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(
                            goodsItem.china_amount.sum_amount /
                              datas.exchange_rate
                          )
                        )
                      }}￥)
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="showAllRow"
                v-if="buyGoodsArr && buyGoodsArr.length > 2"
              >
                <img
                  class="jiahao"
                  src="../../assets/icon/jiahao_fill.svg"
                  alt=""
                  v-if="!showGoodsArrAll"
                  @click="showGoodsArrAll = !showGoodsArrAll"
                />
                <img
                  v-else
                  class="jiahao"
                  src="../../assets/icon/jianhao.svg"
                  alt=""
                  @click="showGoodsArrAll = !showGoodsArrAll"
                />
              </div>
            </div>
          </paySuccessInfoBlockVue>
        </div>
        <!-- <div class="payStep2Right">
          <h3 class="nextTitle">
            {{ $fanyi("质量控制和处理") }}
          </h3>
          <div class="nextStepBox">
            <div class="nextStepOpt">
              <div class="nextStepNum">1</div>
              <div class="nextStepCon">
                <h4>{{ $fanyi("我们从工厂接收你们的产品") }}</h4>
                <p>
                  {{ $fanyi("购买您的产品的过程已经开始。") }}
                  <br />
                  {{ $fanyi("我们期待在我们的仓库收到你们的产品。") }}
                </p>
              </div>
            </div>
            <div class="nextStepOpt">
              <div class="nextStepNum">2</div>
              <div class="nextStepCon">
                <h4>{{ $fanyi("质量控制和处理") }}</h4>
                <p
                  v-html="
                    $fanyi(
                      '我们收到产品并开始执行质量控制和额外服务(如有需要)。'
                    )
                  "
                ></p>
              </div>
            </div>
            <div class="nextStepOpt">
              <div class="nextStepNum">3</div>
              <div class="nextStepCon">
                <h4>{{ $fanyi("运费和运输报价") }}</h4>
                <p>
                  {{
                    $fanyi("我们计算最好的运费报价，并在5-7天内装运你的货物。")
                  }}
                </p>
              </div>
            </div>
            <div class="nextStepOpt">
              <div class="nextStepNum">4</div>
              <div class="nextStepCon">
                <h4>{{ $fanyi("运费的支付") }}</h4>
                <p>
                  {{
                    $fanyi(
                      "付款后，您的货物将被送到我们在巴塞罗那的仓库和您的送货地址。"
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 配送单支付页面 -->
    <div v-else v-loading.fullscreen.lock="payLoading">
      <Dialog ref="dialog" :config="config" />

      <howItWorksVue ref="howItWorksDia" :ispeisong="true"></howItWorksVue>

      <div class="payStep1 payPage" v-if="step == 1">
        <div class="payLeftCon">
          <!-- 联系人 -->
          <div class="linkman" style="pointer-events: none">
            <h1>
              <span>
                {{ $fanyi("进口商联系人") }}
              </span>
              <el-select
                v-model="actNum.jinkoushang"
                @change="findValue($event, 'jinkoushang')"
              >
                <el-option
                  v-for="(additem, addindex) in userAddressList.jinkoushang"
                  :key="additem.id"
                  :label="$fanyi('地址') + (addindex + 1)"
                  :value="additem.id"
                >
                </el-option>
              </el-select>
            </h1>
            <jinkoushangForm ref="jinkoushangref"></jinkoushangForm>
          </div>
          <!-- 收货地址 -->
          <div class="linkman" style="pointer-events: none">
            <h1>
              <span>
                {{ $fanyi("支付页面收货地址") }}
              </span>
              <el-select
                v-model="actNum.shouhuodizhi"
                @change="findValue($event, 'shouhuodizhi')"
              >
                <el-option
                  v-for="(additem, addindex) in userAddressList.shouhuodizhi"
                  :key="additem.id"
                  :label="$fanyi('地址') + (addindex + 1)"
                  :value="additem.id"
                >
                </el-option>
              </el-select>
            </h1>
            <dizhiForm ref="dizhiref"></dizhiForm>
          </div>
          <div class="goodsBox" :class="{ GoodsArrAll: showGoodsArrAll }">
            <!-- 配送单的订单号并不是固定只有一个的,而是会有多个的,所以先不做 -->
            <h1>
              {{ $fanyi("订单摘要") }}
              <!-- <span class="goodsBoxtishi"
                >{{ $fanyi("订单号") }}:{{ $route.query.order_sn }}</span
              > -->
            </h1>

            <div class="goodsArr">
              <div class="goodsHead goodsTr">
                <div class="goodsTd goodshe goodsName"></div>
                <div class="goodsTd goodshe goodsNumber">
                  {{ $fanyi("购买金额") }}
                </div>
                <div class="goodsTd goodshe goodsPrice">
                  {{ $fanyi("产品的支出总额") }}
                </div>
              </div>
              <div
                class="goodsOpt goodsTr"
                v-for="goodsItem in buyGoodsArr"
                :key="'good' + goodsItem.id"
              >
                <div class="goodsTd goodsBo goodsName">
                  <el-popover placement="right" trigger="hover">
                    <img
                      :src="goodsItem.pic"
                      alt=""
                      style="width: 300px; height: 300px"
                    />

                    <el-image slot="reference" :src="goodsItem.pic">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                  <el-popover placement="bottom" trigger="hover" width="800">
                    {{ goodsItem.goods_title }}
                    <span slot="reference" class="goodsTitle">
                      {{ goodsItem.goods_title }}
                    </span>
                  </el-popover>
                </div>
                <div class="goodsTd goodsBo goodsNumber">
                  {{ goodsItem.confirm_num }}
                </div>
                <div class="goodsTd goodsBo goodsPrice">
                  <p class="eu">
                    {{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsItem.confirm_num *
                            goodsItem.confirm_price *
                            goodsItem.exchange_rate
                        )
                      )
                    }}€
                  </p>
                  <p>
                    ({{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsItem.confirm_num * goodsItem.confirm_price
                        )
                      )
                    }}￥)
                  </p>
                </div>
                <div class="henXian"></div>
              </div>
            </div>
            <div class="showAllRow">
              <img
                class="jiahao"
                src="../../assets/icon/jiahao_fill.svg"
                alt=""
                v-if="!showGoodsArrAll"
                @click="showGoodsArrAll = !showGoodsArrAll"
              />
              <img
                v-else
                class="jiahao"
                src="../../assets/icon/jianhao.svg"
                alt=""
                @click="showGoodsArrAll = !showGoodsArrAll"
              />
            </div>
          </div>
        </div>
        <div class="payRightCon">
          <h1 class="fukuan">{{ $fanyi("付款方式") }}</h1>
          <div>
            <div class="payTypeChiose">
              <div class="allPayType">
                <div @click="payType = 1">{{ $fanyi("信用卡") }}</div>
                <div @click="payType = 0">{{ $fanyi("银行转帐") }}</div>
              </div>
              <div
                class="chioseBlock"
                :class="{ xinyongka: payType == 0 }"
              ></div>
            </div>
            <div class="detailedCost">
              <span>{{ $fanyi("产品总价") }}</span>
              <span
                >{{ $fun.EURNumSegmentation(datas.order_price_eu || 0) }}€</span
              >
            </div>
            <div class="detailedCost">
              <span>{{ $fanyi("运费总价") }}</span>
              <span>
                {{
                  $fun.EURNumSegmentation(
                    isPeiSongDan
                      ? $fun.ceil(payAllCNPrice)
                      : $fun.ceil(payAllCNPrice * $store.state.exchangeRate)
                  )
                }}</span
              >
            </div>
            <div class="totalPrice">
              <span>{{ $fanyi("产品支出总额") }}</span>
              <span>
                <!-- {{ payAllCNPrice }} -->
                {{
                  $fun.EURNumSegmentation(
                    isPeiSongDan
                      ? $fun.ceil(payAllCNPrice)
                      : $fun.ceil(payAllCNPrice * $store.state.exchangeRate)
                  )
                }}€</span
              >
            </div>
            <div class="payInfoBox">
              <div class="bankPay" v-if="payType == 0">
                <div class="bankIcon">
                  <img src="../../assets/icon/santander.png" alt="" />
                </div>
                <h2 class="bankName">{{ $fanyi("接收银行信息") }}</h2>
                <div class="banktishi">
                  {{
                    $fanyi(
                      "使用以下银行信息通过您的网上银行或移动应用程序进行转账。一旦订单被处理，就会进行购买。"
                    )
                  }}
                </div>
                <div class="howToWork" @click="openHelpDia">
                  {{ $fanyi("如何运作") }}
                </div>
                <div class="canKao">
                  {{ $fanyi("使用订单号作为付款概念/参考:") }}
                </div>
                <div class="jiaoYiHao">
                  {{ $route.query.porder_sn }}
                </div>
                <div class="shiLiXinXi">
                  <p>
                    <span>{{ $fanyi("收件人名称") }}</span
                    ><span>ELOSUNG EUROPE S.A.</span>
                  </p>
                  <p>
                    <span>{{ $fanyi("公司地址cz") }}</span
                    ><span
                      >Carrer de Francesc Teixidó, 43, 08918 Badalona,
                      Barcelona, España
                    </span>
                  </p>
                  <p>
                    <span>{{ $fanyi("银行账户") }}</span
                    ><span>ES95 0049 1819 1329 1107 9908 </span>
                  </p>
                  <p>
                    <span>{{ $fanyi("SWIFT帐户") }}</span
                    ><span>BSCHESMMXXX</span>
                  </p>
                  <p>
                    <span>{{ $fanyi("银行名称") }}</span
                    ><span>Santander</span>
                  </p>

                  <p><span>Nº CIF</span><span>A-05270319</span></p>
                </div>
                <div class="bankPayForm">
                  <!-- 银行汇款信息 -->
                  <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                  >
                    <el-form-item
                      :label="$fanyi('汇款日期') + '：'"
                      prop="pay_date"
                    >
                      <el-date-picker
                        v-model="ruleForm.pay_date"
                        type="date"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :placeholder="$fanyi('选择日期')"
                      >
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item
                      :label="$fanyi('汇款人') + '：'"
                      prop="pay_realname"
                    >
                      <el-input v-model="ruleForm.pay_realname"></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$fanyi('汇款金额') + '：'"
                      prop="pay_amount"
                    >
                      <el-input
                        v-model="ruleForm.pay_amount"
                        @change="set_pay_amount()"
                      ></el-input>
                      <!-- <span class="payPrice">
                        {{
                          $fun.EURNumSegmentation(
                            $fun.ceil(ruleForm.pay_amount)
                          )
                        }}
                      </span> -->
                    </el-form-item>
                    <el-form-item
                      :label="$fanyi('备注') + '：'"
                      prop="pay_remark"
                    >
                      <el-input v-model="ruleForm.pay_remark"></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$fanyi('汇款截图') + '：'"
                      prop="pay_image_url"
                    >
                      <div class="upLoadBox">
                        <el-upload
                          class="avatar-uploader"
                          action="https://laravel.rakumart.es/api/common/uploadFile"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          name="file"
                          :before-upload="beforeAvatarUpload"
                        >
                          <div v-if="ruleForm.pay_image_url" class="elseImg">
                            <el-image
                              :src="ruleForm.pay_image_url"
                              class="pay_image"
                            >
                              <div slot="error" class="image-slot">
                                <i class="el-icon-folder-checked"></i>
                              </div>
                              <div slot="placeholder">
                                <i class="el-icon-loading"></i>
                              </div>
                            </el-image>
                          </div>

                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                          ></i>
                        </el-upload>
                        <a
                          class="fileName"
                          target="_blank"
                          :href="fileName.url"
                        >
                          {{ fileName.name }}
                        </a>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
                <button class="bankPayBtn" @click="submitForm('ruleForm')">
                  {{ $fanyi("进行付款") }}
                </button>
              </div>
              <div class="creditCardPay" v-else>
                <div class="cardIcon">
                  <div>
                    <img src="../../assets/icon/santander.png" alt="" />
                  </div>
                  <div><img src="../../assets/icon/redses.png" alt="" /></div>
                </div>
                <div class="xuanze">
                  <span>{{ $fanyi("Redsys Santander支付网关") }}</span>

                  <div class="cardiconBox">
                    <div><img src="../../assets/icon/visa.png" alt="" /></div>
                    <div>
                      <img
                        src="../../assets/icon/mastercard_old_payment_method_icon_142751.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        src="../../assets/icon/maestro_old_payment_method_icon_142753.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="wangguantishi">
                  {{ $fanyi("您将被重定向到Redsys Santander支付网关") }}
                </div>
                <div style="display: none">
                  <form
                    action="https://sis.redsys.es/sis/realizarPago"
                    method="post"
                    id="redsys_form"
                    name="redsys_form"
                  >
                    <input
                      type="hidden"
                      name="Ds_MerchantParameters"
                      :value="cardPayData.Ds_MerchantParameters"
                    />
                    <input
                      type="hidden"
                      name="Ds_Signature"
                      :value="cardPayData.Ds_Signature"
                    />
                    <input
                      type="hidden"
                      name="Ds_SignatureVersion"
                      :value="cardPayData.Ds_SignatureVersion"
                    />
                    <input
                      type="submit"
                      name="btn_submit"
                      id="btn_id"
                      value="Enviar"
                      style="font-size: 14px; color: #ff00c1"
                    />
                  </form>
                </div>

                <button class="cardPayBtn" @click="goCreditCardPay">
                  {{ $fanyi("银行卡进行付款") }}
                </button>
                <div class="zhenCe">
                  <el-checkbox v-model="checked"></el-checkbox>
                  <span class="xieYiwenzi">
                    He leído y acepto los
                    <span
                      class="Link"
                      @click="
                        $fun.routerToPage(
                          '/condiciones-generales-de-contratacion'
                        )
                      "
                      style="margin-left: 0"
                      >términos y condiciones</span
                    >
                    así como la
                    <span
                      class="Link"
                      @click="$fun.routerToPage('/politica-privacidad')"
                      style="margin-left: 0"
                      >política de privacidad</span
                    >
                    y autorizo el envío de comunicaciones sobre los productos de
                    Rakumart.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="payStep2 payPage" v-if="step == 2">
        <div class="payStep2Left ispeison">
          <!-- 成功消息框 -->
          <div class="successMesBox">
            <div class="successMesBoxCon">
              <div class="succTitle">
                <div class="icon">
                  <div class="green">
                    <img :src="require('@/assets/icon/gougou.svg')" alt="" />
                  </div>
                </div>
                <span>{{ $fanyi("付款成功") }}</span>
              </div>
              <div class="succBody">
                {{
                  $fanyi(
                    "谢谢，付款成功。很快你就会收到一封电子邮件，里面有确认和详细信息。"
                  )
                }}
              </div>
              <button
                @click="$fun.routerToPage('/user/index')"
                class="successBtn"
              >
                {{ $fanyi("控制面板") }}
              </button>
            </div>
          </div>
          <paySuccessInfoBlockVue :title="$fanyi('支付页面收货地址')">
            <div class="jinkoushang">
              <div class="jinkoushanginfo">
                <p>{{ goodsdatas.orderAddress.consignee_address.contacts }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.address }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.zip_code }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.city }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.country }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.mobile }}</p>
                <p>{{ goodsdatas.orderAddress.consignee_address.email }}</p>
              </div>
              <!-- <changeInformationVue> </changeInformationVue> -->
            </div>
          </paySuccessInfoBlockVue>
          <paySuccessInfoBlockVue :title="$fanyi('进口商联系人')">
            <div class="jinkoushang">
              <div class="jinkoushanginfo">
                <p>{{ goodsdatas.orderAddress.importer_address.contacts }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.company }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.cnpj }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.mobile }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.email }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.address }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.zip_code }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.city }}</p>
                <p>{{ goodsdatas.orderAddress.importer_address.country }}</p>
              </div>
              <!-- <changeInformationVue xiuGaiLeiXin="jinkoushang">
            </changeInformationVue> -->
            </div>
          </paySuccessInfoBlockVue>
          <paySuccessInfoBlockVue :title="$fanyi('订单摘要')">
            <div
              class="goodsBox marginhave"
              :class="{ GoodsArrAll: showGoodsArrAll }"
            >
              <div class="goodsArr">
                <div class="goodsHead goodsTr">
                  <div class="goodsTd goodshe goodsName">
                    {{ $fanyi("产品") }}
                  </div>
                  <div class="goodsTd goodshe goodsNumber">
                    {{ $fanyi("数量") }}
                  </div>
                  <div class="goodsTd goodshe goodsPrice">
                    {{ $fanyi("产品支出总额") }}
                  </div>
                </div>
                <div
                  class="goodsOpt goodsTr"
                  v-for="goodsItem in buyGoodsArr"
                  :key="'good' + goodsItem.id"
                >
                  <div class="goodsTd goodsBo goodsName">
                    <el-popover placement="right" trigger="hover">
                      <img
                        :src="goodsItem.pic"
                        alt=""
                        style="width: 300px; height: 300px"
                      />

                      <el-image slot="reference" :src="goodsItem.pic">
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                        <div slot="placeholder">
                          <i class="el-icon-loading"></i>
                        </div>
                      </el-image>
                    </el-popover>
                    <el-popover placement="bottom" trigger="hover" width="800">
                      {{ goodsItem.goods_title }}
                      <span slot="reference" class="goodsTitle">
                        {{ goodsItem.goods_title }}
                      </span>
                    </el-popover>
                  </div>
                  <div class="goodsTd goodsBo goodsNumber">
                    {{ goodsItem.confirm_num }}
                  </div>
                  <div class="goodsTd goodsBo goodsPrice">
                    <p>
                      {{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(
                            goodsItem.confirm_num *
                              goodsItem.confirm_price *
                              goodsItem.exchange_rate
                          )
                        )
                      }}€
                    </p>
                    <p>
                      ({{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(
                            goodsItem.confirm_num * goodsItem.confirm_price
                          )
                        )
                      }}￥)
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="showAllRow"
                v-if="buyGoodsArr && buyGoodsArr.length > 2"
              >
                <img
                  class="jiahao"
                  src="../../assets/icon/jiahao_fill.svg"
                  alt=""
                  v-if="!showGoodsArrAll"
                  @click="showGoodsArrAll = !showGoodsArrAll"
                />
                <img
                  v-else
                  class="jiahao"
                  src="../../assets/icon/jianhao.svg"
                  alt=""
                  @click="showGoodsArrAll = !showGoodsArrAll"
                />
              </div>
            </div>
          </paySuccessInfoBlockVue>
        </div>
      </div>
    </div>
    <BottomOfPaymentPage v-if="step == 1"></BottomOfPaymentPage>
  </div>
</template>
<script>
const { Base64 } = require("js-base64");
import Dialog from "../../components/public/Dialog.vue";
import { getCurrentDay } from "../../utlis/date";
import HomePageTop from "../../components/head/HomePageTop.vue";
import paymentHead from "./components//paymentHead.vue";
import howItWorksVue from "./components/howItWorks.vue";
import stepShowVue from "./components/stepShow.vue";
import paySuccessInfoBlockVue from "./components/paySuccessInfoBlock.vue";
import changeInformationVue from "./components/changeInformation.vue";
import dizhiForm from "./components/dizhiForm.vue";
import jinkoushangForm from "./components/jinkoushangForm.vue";
import BottomOfPaymentPage from "./components/BottomOfPaymentPage.vue";
export default {
  data() {
    return {
      showGoodsArrAll: false, //显示信息是否展开
      fileName: {}, //上传文件的文件名
      getDataForm: {},
      goodsdatas: {
        logistics_price: 0,
        foreign_express_price: 0,
        clearance_price: 0,
        added_price: 0,
        orderAddress: {
          consignee_address: {
            type: 1, //1=收货地址,2=联系人
            company: "1", //公司名
            zip_code: "", //邮编
            address: "", //地址
            contacts: "", //	联系人
            mobile: "", //手机号
            tel: "1", //固定电话
            email: "", //邮箱
            cnpj: "1", //CNPJ(税号/身份证)
            mark: "1", //唛头
            city: "", //城市名
            country: "", //国家名
          },
          importer_address: {
            type: 2,
            company: "", //公司名
            zip_code: "", //邮编
            address: "", //地址
            contacts: "", //	联系人
            mobile: "", //手机号
            tel: "1", //固定电话]''
            email: "", //邮箱
            cnpj: "", //CNPJ(税号/身份证)
            mark: "1", //唛头
            city: "", //城市名
            country: "", //国家名
          },
        },
      }, //订单信息
      buyGoodsArr: [], //商品信息显示
      payType: 1, //付款方式 0-银行卡，1-信用卡
      checked: false, //是否同意条款
      step: 1, //支付步骤
      cardPayData: {
        Ds_MerchantParameters: "",
        Ds_Signature: "",
        Ds_SignatureVersion: "",
      },

      payAllCNPrice: 0, //支付总价人民币
      payLoading: false, //支付加载中动画控制
      isPeiSongDan: false, //是否是配送单
      addId: {
        shouhuodizhi: "",
        jinkoushang: "",
      },
      shouhuodizhi: {
        type: 1,
        company: "1", //公司名
        zip_code: "", //邮编
        address: "", //地址
        contacts: "", //	联系人
        mobile: "", //手机号
        tel: "1", //固定电话
        email: "", //邮箱
        cnpj: "1", //CNPJ(税号/身份证)
        mark: "1", //唛头
        city: "", //城市名
        country: "", //国家名
      },
      jinkoushang: {
        type: 2,
        company: "", //公司名
        zip_code: "", //邮编
        address: "", //地址
        contacts: "", //	联系人
        mobile: "", //手机号
        tel: "1", //固定电话
        email: "", //邮箱
        cnpj: "", //CNPJ(税号/身份证)
        mark: "1", //唛头
        city: "", //城市名
        country: "", //国家名
      },
      // 用户所有的收货地址
      userAddressList: {
        jinkoushang: [],
        shouhuodizhi: [],
      },
      // 选择的地址编号
      actNum: {
        jinkoushang: "",
        shouhuodizhi: "",
      },
      // 支付页面价格等数据
      datas: {
        sum_discount_price: 0,
        sum_price: 0,
        balance: 0,
      },
      ruleForm: {
        pay_realname: "",
        pay_amount: "",
        pay_remark: "",
        pay_image_url: "",
        pay_date: getCurrentDay(), // getCurrentDay()
        serialNumber: "", //流水号
      },
      // 银行卡支付输入框限制数据
      rules: {
        pay_date: [
          {
            required: true,
            message: this.$fanyi("汇款日期不能为空"),
            trigger: "blur",
          },
        ],
        pay_amount: [
          {
            required: true,
            message: this.$fanyi("汇款金额不能为空"),
            trigger: "blur",
          },
          {
            validator: this.ContrastVerificationCode,
            trigger: "blur",
          },
        ],
        pay_remark: [
          {
            required: true,
            message: this.$fanyi("备注不能为空"),
            trigger: "blur",
          },
        ],
        pay_realname: [
          {
            required: true,
            message: this.$fanyi("汇款人不能为空"),
            trigger: "blur",
          },
        ],

        pay_image_url: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
      },
      // 全局加载控制
      loading: null,
      // 弹窗参数
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("付款"),
        txtcenter: true,
        btnTxt: [this.$fanyi("支付"), this.$fanyi("取消")],
      },
      // 支付总价
      payAmount: 0,
    };
  },
  mounted() {},
  created() {
    // console.log(Base64.decode(this.$route.query.Ds_MerchantParameters));
    // 假如是信用卡支付成功返回的链接跳转，就base64解码获取信息并跳转
    var paysuccess = null;
    var routerData = {};
    if (this.$route.query.pay_from) {
      paysuccess = Base64.decode(this.$route.query.pay_from);
      paysuccess = JSON.parse(paysuccess);
      if (paysuccess.order_sn) {
        routerData.order_sn = paysuccess.order_sn;
        this.getDataForm.order_sn = paysuccess.order_sn;
      }
      if (paysuccess.porder_sn) {
        routerData.porder_sn = paysuccess.porder_sn;
        this.getDataForm.porder_sn = paysuccess.porder_sn;
      }
      if (paysuccess.payType) {
        routerData.payType = paysuccess.payType;
        this.getDataForm.step = 2;
        if (paysuccess.payType == 2) {
          this.step = 2;
        }
      }
      //console.log(paysuccess, routerData);
      this.$router.push({
        name: this.$route.name,
        query: routerData,
      });
      //console.log(paysuccess, routerData);
    }

    if (this.$route.query.payType == 2) {
      this.step = 2;
    }
    if (this.$route.query.order_sn && Number(this.$route.query.order_sn) != 0) {
      // this.getgoodsData(this.$route.query.order_sn);
    }
    if (
      this.$route.query.porder_sn &&
      Number(this.$route.query.porder_sn) != 0
    ) {
      this.getpeisonggoodsData();
    }

    if (this.$route.query.porder_sn) {
      this.isPeiSongDan = true;
    } else {
      this.isPeiSongDan = false;
    }

    this.getData();
    this.getUserAddress(true);
  },
  components: {
    HomePageTop,
    paymentHead,
    howItWorksVue,
    Dialog,
    stepShowVue,
    paySuccessInfoBlockVue,
    changeInformationVue,
    dizhiForm,
    jinkoushangForm,
    BottomOfPaymentPage,
  },
  computed: {
    gerenshui() {
      if (this.goodsdatas.invoice_type == 2) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // 根据id匹配选中的地址
    findValue(e, type) {
      let items = this.userAddressList[type].find((item) => item.id == e);
      if (type == "jinkoushang") {
        this.jinkoushang = items;
      } else {
        this.shouhuodizhi = items;
      }
    },
    //获取用户地址列表
    getUserAddress(moren) {
      this.$api.useraddressList().then((res) => {
        if (res.code != 0) return;
        // 如果moren参数为true，就遍历地址数据里的两个地址列表寻找默认地址
        //console.log(res);
        // 获取用户地址信息
        this.userAddressList = {
          jinkoushang: res.data.importer,
          shouhuodizhi: res.data.consignee,
        };
        if (moren) {
          res.data.consignee.forEach((consigneeItem) => {
            if (consigneeItem.acquiescent) {
              this.actNum.shouhuodizhi = consigneeItem.id;
              this.shouhuodizhi = consigneeItem;
              //console.log(consigneeItem.id);
            }
          });
          res.data.importer.forEach((importerItem) => {
            if (importerItem.acquiescent) {
              this.actNum.jinkoushang = importerItem.id;
              this.jinkoushang = importerItem;
            }
          });
        }
      });
    },
    // 手动触发表单验证
    validateField(type, formRef) {
      if (this.ruleForm[type]) this.ruleForm[type] = this.ruleForm[type].trim();
      this.$refs[formRef].validateField(type, (v) => {
        if (v == "") {
        } else {
        }
        this.$forceUpdate();
      });
    },
    // 打开如何运作弹窗
    openHelpDia() {
      this.$refs.howItWorksDia.open();
    },
    // 使用信用卡支付
    async goCreditCardPay() {
      // 如果已勾选同意政策多选框就执行事件
      if (this.checked) {
        this.payLoading = true;
        this.clearUserAddressNullInfo();
        if (this.isPeiSongDan) {
          this.$api
            .createPayApi({
              order_sn: this.isPeiSongDan
                ? this.$route.query.porder_sn
                : this.$route.query.order_sn,
              //  $%3
              //$%4
              // amount: this.isPeiSongDan
              //   ? this.$fun.ceil(this.payAllCNPrice)
              //   : this.$fun.ceil(
              //       this.payAllCNPrice * this.$store.state.exchangeRate
              //     ),
              pay_type: this.isPeiSongDan ? "配送单支付" : "订单支付",
              coupon_ids: this.$route.query.coupon_ids,
            })
            .then((res) => {
              this.payLoading = false;
              if (!res || res.code != 0)
                return this.$message.error(this.$fanyi(res.msg));
              this.cardPayData = res.data.response.list;
              setTimeout(() => {
                document.getElementById("redsys_form").submit();
              }, 200); //必须延迟请求html表单，否则表单拿不到数据
            });
        } else {
          this.$refs.dizhiref.$refs.formRefdd.validate((valid) => {
            if (!valid) {
              this.payLoading = false;
              this.$message.warning(this.$fanyi("必填参数缺失"));
              this.$refs.jinkoushangref.$refs.formRef.validate((valid) => {});
              return false;
            } else {
              this.$api.addressStore(this.shouhuodizhi).then((res) => {
                //////console.log('addressStore',res)

                if (res.code != 0) return (this.payLoading = false);
                this.addId.shouhuodizhi = res.data;
                this.$refs.jinkoushangref.$refs.formRef.validate((valid) => {
                  if (!valid) {
                    this.payLoading = false;
                    this.$message.warning(this.$fanyi("必填参数缺失"));
                    return false;
                  } else {
                    this.$api.addressStore(this.jinkoushang).then((res) => {
                      if (res.code != 0) return (this.payLoading = false);
                      this.addId.jinkoushang = res.data;
                      this.$api
                        .updateOrderAddressId({
                          order_sn: this.$route.query.order_sn,
                          consignee_address_id: this.addId.shouhuodizhi,
                          importer_address_id: this.addId.jinkoushang,
                        })
                        .then((res) => {
                          this.payLoading = true;
                          if (res.code != 0) return (this.payLoading = false);
                          this.$api
                            .createPayApi({
                              order_sn: this.isPeiSongDan
                                ? this.$route.query.porder_sn
                                : this.$route.query.order_sn,
                              // $%3
                              // $%4
                              // amount: this.isPeiSongDan
                              //   ? this.$fun.ceil(this.payAllCNPrice)
                              //   : this.$fun.ceil(
                              //       this.payAllCNPrice *
                              //         this.$store.state.exchangeRate
                              //     ),
                              pay_type: this.isPeiSongDan
                                ? "配送单支付"
                                : "订单支付",
                              coupon_ids: this.$route.query.coupon_ids,
                            })
                            .then((res) => {
                              this.payLoading = false;
                              if (!res || res.code != 0)
                                return this.$message.error(
                                  this.$fanyi(res.msg)
                                );
                              this.cardPayData = res.data.response.list;
                              setTimeout(() => {
                                document.getElementById("redsys_form").submit();
                              }, 200); //必须延迟请求html表单，否则表单拿不到数据
                            });
                        });
                    });
                  }
                });
              });
            }
          });
        }
      } else {
        this.$message.warning(this.$fanyi("使用条款和隐私政策还没有被同意。"));
      }
    },
    // 订单详情数据获取
    getgoodsData(order_sn, methods) {
      // let buydata = [];
      // res.data.detail.forEach((shopsItem) => {
      //   shopsItem.list.forEach((goodsItem) => {
      //     buydata.push(goodsItem);
      //   });
      // });
      // this.goodsdatas = res.data;
      // this.buyGoodsArr = buydata;
      // res.data.detail.forEach((item1) => {
      //   item1.list.forEach((item2) => {
      //     console.log(item2.pic);
      //     if (item2.pic) {
      //       item2.pic = item2.pic.replace("http:", "https:");
      //     }
      //   });
      // });
      // this.buyGoodsArr = buydata;

      if (methods) {
        methods();
      }
    },
    // 配送单详情数据获取
    getpeisonggoodsData() {
      this.$api
        .storageDeliverDetail({ porder_sn: this.$route.query.porder_sn })
        .then((res) => {
          let buydata = [];

          res.data.result.porder_detail.forEach((shopsItem) => {
            shopsItem.order_detail.exchange_rate =
              shopsItem.order.exchange_rate;
            buydata.push(shopsItem.order_detail);
          });
          // res.data.result.orderAddress.consignee_address =
          //   res.data.result.receive;
          // res.data.result.orderAddress.importer_address =
          //   res.data.result.importer;
          res.data.result.orderAddress = {
            consignee_address: res.data.result.receive,
            importer_address: res.data.result.importer,
          };

          this.goodsdatas = res.data.result;

          this.buyGoodsArr = buydata;
        });
    },
    /////////////////////////////////
    tuStepTwo() {
      this.step = 2;
      this.$api
        .orderAfterPayment({
          order_sn: this.$route.query.order_sn,
        })
        .then((res) => {
          ////console.log('orderAfterPayment',res)
          this.datas = res.data;
          this.goodsdatas.orderAddress = res.data.order_address;
          this.buyGoodsArr = res.data.order_detail;
          //接下来的操作
        });
    },
    // 获取金额限制
    ContrastVerificationCode(rule, value, callback) {
      if (
        value <
        (this.$route.query.porder_sn
          ? this.datas.min_bank_pay_amount
          : this.datas.min_bank_pay_amount_eu)
      ) {
        callback(new Error(this.$fanyi("汇款金额不能小于最低汇款金额")));
      } else {
        callback();
      }
    },
    // 付款

    // 获取支付页面数据
    async getData() {
      // 配送单支付
      if (this.isPeiSongDan) {
        let fromData = {
          porder_sn: this.$route.query.porder_sn || this.getDataForm.porder_sn,
          coupon_ids: this.$route.query.coupon_ids,
        };

        await this.$api.storagePOrderPayment(fromData).then((res) => {
          if (res.code != 0 && this.step != 2) {
            if (res.msg == "该订单状态不可支付") {
              return this.$message.success(this.$fanyi(res.msg));
            } else {
              return this.$message.error(this.$fanyi(res.msg));
            }
          }
          this.datas.min_bank_pay_amount = res.data.min_bank_pay_amount;
          this.datas.sum_discount_price = res.data.porder_amount;
          if (Number(res.data.balance) > 0) {
            this.datas.balance = res.data.balance;
          } else {
            this.datas.balance = 0;
          }
          console.log(
            res.data.min_bank_pay_amount,
            this.$fun.ceil(res.data.min_bank_pay_amount)
          );
          this.ruleForm.pay_amount = this.toEuroFormat(
            res.data.min_bank_pay_amount
          );
          this.payAllCNPrice = res.data.porder_amount;
        });
      } else {
        // 订单支付
        let datas = {
          order_sn: this.$route.query.order_sn || this.getDataForm.order_sn,
        };
        if (this.$route.query.coupon_ids) {
          datas.coupon_ids = this.$route.query.coupon_ids;
        }

        await this.$api.orderPayment(datas).then((res) => {
          if (res.msg == "该订单已支付完成" || this.getDataForm.step) {
            this.tuStepTwo();
            return this.$message.success(this.$fanyi("该订单已成功支付"));
          }
          if (res.code != 0 && this.step != 2) {
            if (res.msg == "该订单状态不可支付") {
              return this.$message.success(this.$fanyi(res.msg));
            } else {
              return this.$message.error(this.$fanyi(res.msg));
            }
          }

          this.datas = res.data;
          this.goodsdatas.orderAddress =
            res.data.order_goods_amount_data.order_address;
          this.buyGoodsArr = res.data.order_goods_amount_data.order_detail;

          // 2023/2/8 16:21: 表单里汇款金额应该取min_bank_pay_amount
          this.ruleForm.pay_amount = this.toEuroFormat(
            res.data.min_bank_pay_amount * this.$store.state.exchangeRate
          );
          // 2022/11/17 总价应该是sum_discount_price,不是sum_price

          this.payAllCNPrice = res.data.sum_discount_price;
          //console.log("总价", this.payAllCNPrice);
        });
      }
    },
    // 标签点击事件
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    // 清除地址栏数据里空的选项
    clearUserAddressNullInfo() {
      for (let shouhuoI in this.shouhuodizhi) {
        if (
          this.shouhuodizhi[shouhuoI] == null ||
          this.shouhuodizhi[shouhuoI] == ""
        ) {
          delete this.shouhuodizhi[shouhuoI];
        }
      }
      for (let jinkouI in this.jinkoushang) {
        if (
          this.jinkoushang[jinkouI] == null ||
          this.jinkoushang[jinkouI] == ""
        ) {
          delete this.jinkoushang[jinkouI];
        }
      }
    },
    // 将数字转化为欧洲金额格式
    toEuroFormat(number) {
      // 将数字转换为字符串，并保留两位小数
      let parts = number.toFixed(2).split(".");

      // 获取整数部分和小数部分
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // 使用正则表达式为整数部分添加千位分隔符
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      // 返回格式化的字符串
      return `${integerPart},${decimalPart}`;
    },
    pay_amount_change(v) {
      let restr = null;
      if (v) {
        restr = v
          .replace(/\./g, "")
          .replace(",", ".")
          .replace("€", "");
      }
      return restr || v;
    },
    set_pay_amount() {
      let str_copy = this.ruleForm.pay_amount
        .replace("€", "")
        .replace(",", "")
        .replace(".", "");

      if (!isNaN(str_copy)) {
        this.ruleForm.pay_amount = this.ruleForm.pay_amount
          .replace("€", "")
          .replace("-", "");
      } else {
        this.ruleForm.pay_amount = "";
      }
    },
    // 银行支付
    submitForm(formName) {
      // return console.log(this.pay_amount_change(this.ruleForm.pay_amount));
      // if (!this.ruleForm.pay_image_url)
      //   return this.$message(this.$fanyi("请上传支付截图"));
      this.payLoading = true;
      // 如果是订单就先传地址再调用付款，配送单就直接调付款
      if (!this.isPeiSongDan) {
        this.$refs.dizhiref.$refs.formRefdd.validate((valid) => {
          if (!valid) {
            this.payLoading = false;
            this.$message.warning(this.$fanyi("必填参数缺失"));
            this.$refs.jinkoushangref.$refs.formRef.validate((valid) => {});
            return false;
          } else {
            // 清除地址栏数据里空的选项
            this.clearUserAddressNullInfo();
            this.$api.addressStore(this.shouhuodizhi).then((res) => {
              if (res.code != 0) {
                this.payLoading = false;
                return this.$message.error(this.$fanyi(res.msg));
              }
              this.addId.shouhuodizhi = res.data;
              this.$refs.jinkoushangref.$refs.formRef.validate((valid) => {
                if (!valid) {
                  this.payLoading = false;
                  this.$message.warning(this.$fanyi("必填参数缺失"));
                  return false;
                } else {
                  this.$api.addressStore(this.jinkoushang).then((res) => {
                    if (res.code != 0) {
                      this.payLoading = false;
                      return this.$message.error(this.$fanyi(res.msg));
                    }
                    this.addId.jinkoushang = res.data;
                    this.$api
                      .updateOrderAddressId({
                        order_sn: this.$route.query.order_sn,
                        consignee_address_id: this.addId.shouhuodizhi,
                        importer_address_id: this.addId.jinkoushang,
                      })
                      .then((res) => {
                        if (res.code != 0) {
                          this.payLoading = false;
                          return this.$message.error(this.$fanyi(res.msg));
                        }
                        this.$refs[formName].validate((valid) => {
                          if (valid) {
                            this.payLoading = false;
                            this.$refs.dialog.open(
                              this.$fanyi("是否使用银行卡支付"),
                              () => {
                                this.payLoading = true;
                                if (!this.isPeiSongDan) {
                                  this.$api
                                    .orderBankPayment({
                                      order_sn: this.$route.query.order_sn,
                                      coupon_ids: this.$route.query.coupon_ids,
                                      bill_method: 4,
                                      pay_date: this.ruleForm.pay_date,
                                      pay_realname: this.ruleForm.pay_realname,
                                      pay_amount: this.pay_amount_change(
                                        this.ruleForm.pay_amount
                                      ),
                                      pay_remark: this.ruleForm.pay_remark,
                                      pay_image_url: this.ruleForm
                                        .pay_image_url,
                                    })
                                    .then((res) => {
                                      this.payLoading = false;
                                      if (res.code != 0) {
                                        return this.$message.error(
                                          this.$fanyi(res.msg)
                                        );
                                      }

                                      this.serialNumber = res.data.serialNumber;
                                      this.payAmount = res.data.payAmount;
                                      // this.getgoodsData(
                                      //   this.$route.query.order_sn
                                      // );
                                      this.step++;
                                    });
                                } else {
                                  this.$api
                                    .storagePOrderBankPayment({
                                      porder_sn: this.$route.query.porder_sn,
                                      bill_method: 4,
                                      pay_date: this.ruleForm.pay_date,
                                      pay_realname: this.ruleForm.pay_realname,
                                      pay_amount: this.pay_amount_change(
                                        this.ruleForm.pay_amount
                                      ),
                                      pay_remark: this.ruleForm.pay_remark,
                                      pay_image_url: this.ruleForm
                                        .pay_image_url,
                                    })
                                    .then((res) => {
                                      this.payLoading = false;
                                      //console.log("orderBankPayment", res);
                                      if (res.code != 0)
                                        return this.$message.error(
                                          this.$fanyi(res.msg)
                                        );

                                      this.serialNumber = res.data.serialNumber;
                                      this.payAmount = res.data.payAmount;
                                      // this.getgoodsData();
                                      this.getpeisonggoodsData();
                                      this.step++;
                                    });
                                }
                              },
                              () => {
                                this.$message(this.$fanyi("已取消支付"));
                              }
                            );
                          } else {
                            //console.log("error submit!!");
                            this.payLoading = false;
                            this.$message.warning(this.$fanyi("必填参数缺失"));
                            return false;
                          }
                        });
                      });
                  });
                }
              });
            });
          }
        });
      } else {
        this.$refs.dialog.open(this.$fanyi("是否使用银行卡支付"), () => {
          this.payLoading = true;
          if (!this.isPeiSongDan) {
            this.$api
              .orderBankPayment({
                order_sn: this.$route.query.order_sn,
                coupon_ids: this.$route.query.coupon_ids,
                bill_method: 4,
                pay_date: this.ruleForm.pay_date,
                pay_realname: this.ruleForm.pay_realname,
                pay_amount: this.pay_amount_change(this.ruleForm.pay_amount),
                pay_remark: this.ruleForm.pay_remark,
                pay_image_url: this.ruleForm.pay_image_url,
              })
              .then((res) => {
                this.payLoading = false;
                if (res.code != 0)
                  return this.$message.error(this.$fanyi(res.msg));

                this.serialNumber = res.data.serialNumber;
                this.payAmount = res.data.payAmount;
                // this.getgoodsData(this.$route.query.order_sn);
                this.step++;
              });
          } else {
            this.$api
              .storagePOrderBankPayment({
                porder_sn: this.$route.query.porder_sn,
                coupon_ids: this.$route.query.coupon_ids,
                bill_method: 4,
                pay_date: this.ruleForm.pay_date,
                pay_realname: this.ruleForm.pay_realname,
                pay_amount: this.pay_amount_change(this.ruleForm.pay_amount),
                pay_remark: this.ruleForm.pay_remark,
                pay_image_url: this.ruleForm.pay_image_url,
              })
              .then((res) => {
                this.payLoading = false;
                //console.log("orderBankPayment", res);
                if (res.code != 0)
                  return this.$message.error(this.$fanyi(res.msg));

                this.serialNumber = res.data.serialNumber;
                this.payAmount = res.data.payAmount;
                // this.getgoodsData();
                this.getpeisonggoodsData();
                this.step++;
              });
          }
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.fileName.url = res.data;
      this.fileName.name = file.name;
      this.loading.close();
      this.ruleForm.pay_image_url = res.data;
      this.validateField("pay_image_url", "ruleForm");
    },
    beforeAvatarUpload(file) {
      //  2022/12/1左右 西班牙方面提出解开文件格式的限制
      // const isJPG =
      //   ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      // //console.log(file.type);
      // if (!isJPG) {
      //   this.$message.error(this.$fanyi("请上传图片"));
      //   return isJPG;
      // }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("请上传图片");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin.scss";
.paymentPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}
.payPage {
  width: 1400px;
  margin: 12px auto 0;
}

.payStep1 {
  display: flex;
  align-items: flex-start;

  .payLeftCon {
    width: 800px;

    .linkman {
      width: 800px;
      // height: 466px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      padding: 40px 30px 10px;
      margin-bottom: 40px;

      h1 {
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .payRightCon {
    width: 560px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 40px;
    margin: 0 0 0 40px;

    .fukuan {
      height: 18px;
      font-size: 18px;

      font-weight: 600;
      color: #2f2f2f;
      line-height: 18px;
      margin-bottom: 30px;
    }

    .payTypeChiose {
      width: 480px;
      height: 68px;
      background: #f6f6f6;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      position: relative;
      margin-bottom: 30px;

      .allPayType {
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          flex: 1;
          height: 68px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          cursor: pointer;
        }
      }

      .chioseBlock {
        width: 228px; //228
        height: 52px;
        background: #ffffff;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        position: absolute;
        top: 8px;
        left: 8px;
        transition: 0.3s;

        &.xinyongka {
          left: 50%;
        }
      }
    }
    .detailedCost {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      padding: 0 3px;

      span {
        font-size: 16px;
        line-height: 30px;
        color: #2f2f2f;
      }
    }
    .totalPrice {
      height: 73px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 3px;
      margin-bottom: 30px;
      border-bottom: #e8e8e8 solid 1px;

      span {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .payInfoBox {
      .bankPay {
        .bankIcon {
          height: 72px;
          border: 1px solid #e8e8e8;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;

          img {
            width: 156px;
            height: 33px;
          }
        }

        .bankName {
          font-size: 16px;
          font-weight: 600;
          color: #2f2f2f;
          margin-bottom: 15.5px;
        }

        .banktishi {
          height: 70px;
          line-height: 23px;
          margin-bottom: 15.5px;
          font-size: 14px;
          font-weight: 400;
          color: #787878;
        }

        .howToWork {
          font-size: 13px;
          font-weight: 400;
          text-decoration: underline;
          color: #1c2899;
          line-height: 13px;
          margin-bottom: 40px;
          cursor: pointer;
        }

        .canKao {
          font-size: 16px;
          font-weight: 400;
          color: #2f2f2f;
          line-height: 16px;
          margin-bottom: 10px;
        }

        .jiaoYiHao {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48px;
          border: 1px solid #e8e8e8;
          border-radius: 6px;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 25px;
        }

        .shiLiXinXi {
          border-bottom: 1px solid #e8e8e8;

          p {
            line-height: 25px;
            margin-bottom: 35px;
            font-size: 16px;
            font-weight: 400;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            span {
              &:last-child {
                flex: 0 0 270px;
                text-align: right;
              }
            }
          }
        }

        .bankPayForm {
          padding-top: 43px;
          padding-bottom: 10px;

          /deep/.el-form-item__label {
            display: block;
            float: none;
            text-align: left;
            line-height: 14px;
            height: 14px;
            margin-bottom: 15px;
          }

          /deep/.el-form-item__content {
            float: none;

            .el-input__inner {
              width: 480px;
              padding-left: 15px;
            }

            .el-date-editor {
              width: 480px;

              .el-input__prefix {
                position: absolute;
                width: 40px;
                left: unset;
                right: 10px;
              }

              .el-input__suffix-inner {
                margin-right: 40px;
              }
            }
          }

          .upLoadBox {
            display: flex;
            align-items: center;

            .fileName {
              margin-left: 15px;
              color: #216ab3;
              text-decoration: underline;
            }

            .el-icon-folder-checked {
              font-size: 50px;
              color: #1e2997;
            }
          }

          /deep/.avatar-uploader .el-upload {
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            overflow: hidden;

            .elseImg {
              width: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 120px;

              /deep/.el-image.pay_image {
                $imgSize: 120px;
                width: $imgSize;
                // height: $imgSize;
                transition: 0.3s;
                text-align: center;

                i {
                  font-size: $imgSize/4;
                  line-height: $imgSize;
                }
              }

              img {
                max-width: 120px;
              }
            }
          }

          .avatar-uploader .el-upload:hover {
            border-color: #409eff;
          }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 120px;
            height: 120px;
            line-height: 120px;
            text-align: center;
          }

          .avatar {
            width: 120px;
            height: 120px;

            display: block;
          }
        }

        .bankPayBtn {
          width: 100%;
          height: 74px;
          background: #499edb;
          border: 1px solid #e8e8e8;
          border-radius: 6px;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .creditCardPay {
        .cardIcon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;

          > div {
            width: 230px;
            height: 72px;
            border: 1px solid #e8e8e8;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 140px;
            }
          }
        }

        .xuanze {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 25px;
          margin-bottom: 5px;

          span {
            font-size: 16px;
            font-weight: 600;
            color: #2f2f2f;
            line-height: 25px;
          }

          .cardiconBox {
            display: flex;
            align-items: center;

            > div {
              width: 50px;
              height: 25px;
              border: 1px solid #e8e8e8;
              border-radius: 6px;
              margin-left: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .wangguantishi {
          color: #bebebe;
          font-size: 14px;
          margin-bottom: 10px;
        }

        .cardPayBtn {
          width: 480px;
          height: 74px;
          background: #499edb;
          border: 1px solid #e8e8e8;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 20px;

          color: #ffffff;

          &:hover {
            opacity: 0.7;
          }
        }

        .zhenCe {
          display: flex;
          align-items: flex-start;

          /deep/.el-checkbox__input {
            height: 16px;
          }

          span {
            display: block;
            margin-left: 12px;
            // width: 396px;
            font-size: 16px;

            font-weight: 400;
            color: #2f2f2f;
            line-height: 24px;
            margin-top: -2px;

            .Link {
              color: #1e2997;
              text-decoration: underline;
              cursor: pointer;
              display: inline;
            }
          }
        }
      }
    }
  }

  .wuLiuZuiZhongBaoJia {
    width: 800px;
    // height: 426px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 40px 40px 0;
    margin-bottom: 20px;

    h1 {
      font-size: 18px;
      font-weight: 600;
      color: #2f2f2f;
      line-height: 18px;
      margin-bottom: 20px;
    }

    h2 {
      height: 15px;
      font-size: 14px;
      font-weight: 400;
      color: #2f2f2f;
      line-height: 14px;
      margin-bottom: 20px;
    }

    .priceBox {
      .priceOpt {
        height: 53px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: #ededf5 solid 1px;

        label {
          font-size: 14px;
          font-weight: 400;
          color: #868686;
          line-height: 16px;
        }

        .PriceOuYuan {
          font-size: 16px;
          font-weight: bold;
          color: #2f2f2f;
          line-height: 16px;
        }
      }
    }

    .shouldPayPrice {
      padding: 40px 0;
      font-size: 18px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      color: #272727;
      line-height: 18px;
    }
  }
}

.payStep2 {
  margin: 0 auto;
  width: 800px;
  padding-bottom: 56px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .payStep2Left {
    width: 800px;
    .successMesBox {
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      padding-top: 33px;
      margin-bottom: 20px;

      .successMesBoxCon {
        width: 512px;
        margin: 0 auto 47px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .succTitle {
          margin-bottom: 25px;

          .icon {
            width: 109px;
            height: 108px;
            background: rgba($color: #bff1e3, $alpha: 0.2);
            border-radius: 50%;
            display: flex;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            margin-bottom: 19px;
            .green {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 46px;
              height: 45px;
              background: #57bd9b;
              border-radius: 50%;
              img {
                width: 30px;
                height: 24px;
              }
            }
          }

          span {
            font-size: 22px;
            font-weight: 600;
            line-height: 1;
          }
        }

        .succBody {
          margin: -5px 0 25px 0;
          font-size: 16px;
          font-weight: 400;
          color: #2f2f2f;
          line-height: 26px;
          text-align: center;
        }

        .successBtn {
          width: 242px;
          height: 58px;
          background: #1c2899;
          border: 1px solid #e8e8e8;
          border-radius: 6px;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
        }
      }
    }

    &.ispeison {
      margin: 0 auto;
    }
  }

  .payStep2Right {
    padding: 40px 30px;
    width: 560px;
    height: 556px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;

    .nextTitle {
      font-size: 18px;
      font-weight: 600;
      color: #2f2f2f;
      line-height: 18px;
      margin-bottom: 10px;
    }

    .nextStepBox {
      .nextStepOpt {
        border-bottom: #e7e7e7 1px solid;

        &:last-child {
          border-bottom: none;
        }

        height: 121px;
        padding: 29px 0 30px;
        display: flex;
        align-items: flex-start;

        .nextStepNum {
          width: 41px;
          height: 41px;
          background: #f7f7f7;
          border: 1px solid #e7e7e7;
          border-radius: 50%;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          justify-content: center;
          margin-right: 20px;
          align-items: center;
        }

        .nextStepCon {
          h4 {
            font-size: 16px;
            font-weight: 400;
            color: #21213b;
            line-height: 17px;
            margin-bottom: 10px;
            margin-top: -1px;
          }

          p {
            width: 436px;
            font-size: 14px;

            font-weight: 400;
            color: #66667e;
            line-height: 21px;
            margin-top: -3.5px;
          }
        }
      }
    }
  }
}

/deep/.is-error .el-input__inner {
  border-color: red !important;
}

/deep/.is-error .el-upload {
  border-color: red !important;
}

.goodsBox {
  width: 800px;
  max-height: 288px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 30px 0 0;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  .buyOrderSn {
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    b {
      font-size: 16px;
      font-weight: 600;
    }
  }
  &.marginhave {
    margin-left: -40px;
    margin-top: -20px;
    margin-bottom: -20px;
    padding-top: 0;
    border: none;
    max-height: 222px;
    min-height: 222px;
  }

  h1 {
    padding: 0 40px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #2f2f2f;
    line-height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .goodsBoxtishi {
      font-size: 16px;
      height: 17px;
      font-weight: 400;
      color: #2f2f2f;
      line-height: 17px;
    }
  }

  .goodsArr {
    padding: 0 40px;
    .goodsTr {
      display: flex;
      align-items: center;
      // 循环的商品行
      &.goodsOpt {
        position: relative;
        padding: 8px 0 12px;
        .henXian {
          width: 100%;
          height: 6px;
          background: #dfdfdf;
          border-radius: 3px;
          position: absolute;
          left: 0;
          bottom: 0px;
        }
      }
      // 所有单元格样式
      .goodsTd {
        font-size: 13px;
        font-weight: 400;
        color: #2f2f2f;
        line-height: 29px;
        flex: 1;
        // 所有单元格标题样式
        &.goodshe {
          margin-top: 17px;
          font-size: 13px;
          line-height: 1;
          font-weight: bold;
          background-color: white;
          display: flex;
          align-items: flex-end;
        }
        // 所有单元格内容样式
        &.goodsBo {
          height: 55px;
        }

        // 单个单元格样式》》》
        // 价格单元格样式
        &.goodsPrice {
          flex: 0 0 164px;
          margin-left: 50px;
          &.goodshe {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.goodsBo {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            line-height: 1;
            .eu {
              font-size: 16px;
              font-weight: 600;
            }
          }

          .mingXi {
            display: block;
            font-size: 12px;
            text-align: center;
            color: #888888;
            line-height: 16px;
            cursor: pointer;
          }
        }
        // 数量单元格样式
        &.goodsNumber {
          border-color: rebeccapurple;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 150px;

          &.goodsBo {
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            text-align: center;
          }
        }
        // 商铺单元格样式
        &.goodsName {
          display: flex;
          /deep/.el-image {
            $imgSize: 55px;
            width: $imgSize;
            height: $imgSize;
            transition: 0.3s;
            text-align: center;
            i {
              font-size: $imgSize/4;
              line-height: $imgSize;
            }
          }

          .goodsTitle {
            padding: 0 30px;
            padding-top: 4px;
            height: 55px;
            font-size: 12px;
            font-weight: 400;
            position: relative;
            top: -5px;
            color: #2f2f2f;
            line-height: 22px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  .showAllRow {
    height: 48px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 4px 4px;
    position: sticky;
    bottom: 0;
    width: 100%;
    left: 0;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .jiahao {
      width: 24px;
      height: 24px;
      margin-right: 32px;
      cursor: pointer;
    }
  }

  &.GoodsArrAll {
    // min-height: 392px;
    max-height: unset !important;
  }
}

.jinkoushang {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .jinkoushanginfo {
    p {
      font-size: 16px;
      font-weight: 400;
      color: #2f2f2f;
      line-height: 24px;
    }
  }
}

.yuGuDaoShouFeiYongMingXi {
  width: 100%;

  p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;

    .title {
      width: 210px;
      text-align: right;
      display: inline-block;
      margin-right: 10px;
    }

    .body {
      display: inline-block;
      color: #ffa724;
      font-size: 12px;
    }
  }

  &.yugu {
    p {
      .title {
        width: 271px !important;
      }
    }
  }
}

.payPrice {
  width: 100%;
  display: block;
  padding-left: 15px;
  background-color: #ffffff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
</style>

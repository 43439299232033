<template>
  <div>
    <el-form
      class="firStepForm"
      :model="formregisterpay"
      :rules="formRulesPay"
      ref="formRef"
    >
      <ul>
        <li>
          <el-form-item prop="contacts" :label="$fanyi('全名')">
            <input
              class="ipt"
              v-bind:class="{
                istrueInput: formInputTrue.contacts,
              }"
              @focus="validateField('contacts')"
              @blur="validateField('contacts')"
              autocomplete="new-password"
              v-model="formregisterpay.contacts"
            />
            <div class="gou" v-if="formInputTrue.contacts">✔</div>
          </el-form-item>
        </li>
        <li>
          <el-form-item prop="company" :label="$fanyi('公司(可选)')">
            <input
              class="ipt"
              v-bind:class="{
                istrueInput: formInputTrue.company,
              }"
              @focus="validateField('company')"
              @blur="validateField('company')"
              autocomplete="new-password"
              v-model="formregisterpay.company"
            />
            <div class="gou" v-if="formInputTrue.company">✔</div>
          </el-form-item>
        </li>
        <li>
          <el-form-item prop="cnpj" :label="$fanyi('CIF / DNI')">
            <input
              class="ipt"
              v-bind:class="{
                istrueInput: formInputTrue.cnpj,
              }"
              @focus="validateField('cnpj')"
              @blur="validateField('cnpj')"
              autocomplete="new-password"
              v-model="formregisterpay.cnpj"
            />
            <div class="gou" v-if="formInputTrue.cnpj">✔</div>
          </el-form-item>
        </li>
        <li>
          <el-form-item prop="mobile" :label="$fanyi('电话')">
            <input
              class="ipt"
              v-bind:class="{
                istrueInput: formInputTrue.mobile,
              }"
              @focus="validateField('mobile')"
              @blur="validateField('mobile')"
              autocomplete="new-password"
              v-model="formregisterpay.mobile"
            />
            <div class="gou" v-if="formInputTrue.mobile">✔</div>
          </el-form-item>
        </li>
        <li>
          <el-form-item prop="email" :label="$fanyi('邮件箱')">
            <input
              class="ipt"
              v-bind:class="{
                istrueInput: formInputTrue.email,
              }"
              @focus="validateField('email')"
              @blur="validateField('email')"
              autocomplete="new-password"
              v-model="formregisterpay.email"
            />
            <div class="gou" v-if="formInputTrue.email">✔</div>
          </el-form-item>
        </li>
        <li>
          <el-form-item prop="address" :label="$fanyi('支付地址')">
            <input
              class="ipt"
              v-bind:class="{
                istrueInput: formInputTrue.address,
              }"
              @focus="validateField('address')"
              @blur="validateField('address')"
              autocomplete="new-password"
              v-model="formregisterpay.address"
            />
            <div class="gou" v-if="formInputTrue.address">✔</div>
          </el-form-item>
        </li>
        <li>
          <div class="formZiDingYi">
            <el-form-item prop="zip_code" :label="$fanyi('邮编')">
              <div class="youbian">
                <input
                  class="ipt"
                  v-bind:class="{
                    istrueInput: formInputTrue.zip_code,
                  }"
                  @focus="validateField('zip_code')"
                  @blur="validateField('zip_code')"
                  autocomplete="new-password"
                  v-model="formregisterpay.zip_code"
                />
                <div class="gou" v-if="formInputTrue.zip_code">✔</div>
              </div>
            </el-form-item>
            <el-form-item prop="city" :label="$fanyi('城市')">
              <div class="chengshi">
                <input
                  class="ipt"
                  v-bind:class="{
                    istrueInput: formInputTrue.city,
                  }"
                  @focus="validateField('city')"
                  @blur="validateField('city')"
                  autocomplete="new-password"
                  v-model="formregisterpay.city"
                />
                <div class="gou" v-if="formInputTrue.city">✔</div>
              </div>
            </el-form-item>
          </div>
        </li>
        <li>
          <el-form-item prop="country" :label="$fanyi('国家')">
            <input
              class="ipt"
              v-bind:class="{
                istrueInput: formInputTrue.country,
              }"
              @focus="validateField('country')"
              @blur="validateField('country')"
              autocomplete="new-password"
              v-model="formregisterpay.country"
            />
            <div class="gou" v-if="formInputTrue.country">✔</div>
          </el-form-item>
        </li>
      </ul>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formInputTrue: {}, //输入框样式控制数据
      formRulesPay: {
        company: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        zip_code: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        address: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        contacts: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        city: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        mobile: [
          // { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
          // {
          //   required: true,
          //   pattern: /^(\w){9}$/,
          //   message: this.$fanyi("手机号输入错误"),
          // },
        ],
        mobile: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        email: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
          {
            type: "email",
            message: this.$fanyi("请输入正确的邮箱地址"),
            trigger: ["blur", "change"],
          },
        ],
        country: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        cnpj: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        company: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        company: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        company: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
      },
    };
  },
  components: {},
  computed: {
    formregisterpay() {
      return (
        this.$parent.jinkoushang || this.$parent.$parent.$parent.jinkoushang
      );
    },
  },
  mounted() {
    if (this.$parent.$parent.$parent.jiancha) {
      this.checkAllInput();
    }
  },
  methods: {
    // 检查所有选项
    checkAllInput() {
      let checkOpt = [
        "address",
        "city",
        "contacts",
        "country",
        "email",
        "mobile",
        "zip_code",
        "company",
        "cnpj",
      ];
      for (let i in checkOpt) {
        this.validateField(checkOpt[i], "formRefdd");
      }
    },
    // 手动触发表单验证
    validateField(type) {
      if (this.formregisterpay[type])
        this.formregisterpay[type] = this.formregisterpay[type].trim();
      this.$refs.formRef.validateField(type, (v) => {
        if (v == "") {
          this.formInputTrue[type] = true;
        } else {
          this.formInputTrue[type] = false;
        }
        this.$forceUpdate();
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../css/mixin.scss";
.firStepForm {
  width: 740px;
  /deep/.el-form-item {
    margin-bottom: 30px;
  }
  /deep/.el-form-item__error {
    top: 50px;
  }
  > ul {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    > li {
      width: 360px;
      display: flex;
      flex-direction: column;
      // 表单标题样式
      /deep/.el-form-item__label {
        padding: 0;
        text-align: left;
        float: none;
        display: block;
        width: 100%;
        line-height: 14px;
        height: 14px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
        &::before {
          display: none;
        }
      }
      /deep/.elistrueInput .el-input__inner {
        background: #f3f6fc;
        border: 1px solid #abbbd2;
        border-radius: 5px;
        position: relative;
      }

      input {
        width: 360px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #dedede;
        font-size: 14px;
        font-weight: 400;
        border-radius: 5px;
        padding: 0 10px;
        transition: 0.4s;
        &::placeholder {
          color: #b6b6b6;
        }
        &:focus {
          border: 1px solid $homePageSubjectColor;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
        }
        &.istrueInput {
          background: #f3f6fc;
          border: 1px solid #abbbd2;
          border-radius: 5px;
          position: relative;
        }
      }

      .gou {
        position: absolute;
        font-weight: bold;
        right: 13px;
        top: 3px;
        color: #417db4;
        &.regCodegou {
          right: 222px;
        }
      }
      .xuanZeGuoJia {
        width: 100%;
        height: 48px;
        background: #f3f6fc;
        border-radius: 5px;

        /deep/.el-input__inner {
          width: 100%;
          height: 48px;
          background: #f3f6fc;
          border: 1px solid #abbbd2;
          border-radius: 5px;
        }
        /deep/.el-icon-arrow-up {
          color: $homePageSubjectColor;
          font-weight: bold;
          font-size: 15px;
        }
      }
      .formZiDingYi {
        //多个输入框自定义样式
        display: flex;
        align-items: center;
        margin-top: -14px;
        .youbian {
          margin-right: 20px;
          input {
            width: 130px;
          }
          .gou {
            right: 33px;
          }
        }
        .chengshi {
          input {
            width: 210px;
          }
        }
      }
    }
    .guoJiaQuHao {
      .shouJIHaoCon {
        display: flex;
        align-items: center;
        .quHao {
          width: 58px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #dedede;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          &.istrueInput {
            background: #f3f6fc;
            border: 1px solid #abbbd2;
            border-radius: 5px;
            position: relative;
          }
        }
        input {
          width: 312px;
        }
      }
    }
    .yanzhengma {
      .yanzhengmaBox {
        display: flex;
        align-items: center;
        input {
          width: 167px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #dedede;
          border-radius: 5px;
          &.istrueInput {
            background: #f3f6fc;
            border: 1px solid #abbbd2;
            border-radius: 5px;
            position: relative;
          }
        }
        button {
          margin-left: 80px;
          width: 195px;
          height: 48px;
          background: $homePageSubjectColor;
          border: 1px solid $homePageSubjectColor;
          border-radius: 5px;
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
    .xieYi {
      width: 360px;
      display: flex;
      align-items: center;
      .fuWuXieYi {
        display: flex;
        align-items: center;
        a {
          width: 165px;
          height: 35px;
          font-size: 14px;

          font-weight: 300;
          color: #2f2f2f;
          line-height: 35px;
          margin: 0 5px 0 10px;
        }
        button {
          width: 182px;
          height: 48px;
          background: #47bd9b;
          border: 1px solid #47bd9b;
          border-radius: 5px;
          color: white;
        }
      }
    }
    .xiaYiBuHuoFanHui {
      .caoZuo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          width: 45%;
          height: 48px;
          background: #47bd9b;
          border: 1px solid #47bd9b;
          border-radius: 5px;
          color: white;
        }
        .shangYiBu {
          background: $homePageSubjectColor;
        }
        .xiaYiBu {
          background: #47bd9b;
        }
      }
    }
  }
  .showregisterInfo {
    width: 360px;
    height: 48px;
    font-size: 13px;
    line-height: 48px;
    border: 1px solid #dedede;
    background-color: #f3f3f3;
    // color: #787777;
    border-radius: 5px;
    padding: 0 10px;
    transition: 0.4s;
  }
}
.ipt {
  padding-right: 30px !important;
}
</style>
